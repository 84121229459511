@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply py-2
        px-8
        border
        shadow-sm
        text-sm
        font-medium
        rounded-md
        bg-gray-700 
        hover:bg-rem-blue
        text-white
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
        focus:ring-rem-gray;
  }
}

.print-design {
  background-position: 50% 50%;
  background-size: cover;
  height: 276px;
  pointer-events: auto;
  transition: all 0.2s ease;
  width: 258px;
}

.print-design:hover {
  transform: scale(1.1);
}

